import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CurrentUserService } from '@common/models/current-user.service';
import { AuthenticatedUser } from '@common/models/users/user-models.service';
import { PlAppGlobalService } from '@common/services';
import { Store } from '@ngrx/store';
import { UserActions } from '../user/store';
import { of, throwError } from 'rxjs';
import { FirebaseService } from '@common/firebase/firebase.service';
import { selectIsFirebaseInitialized } from '@common/firebase/store';
import { AppState } from '../../store';
import { RTParticipant } from './session/store';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
import { DRFRoomModel } from '@common/models/DRF/DRFRoomModel.service';
import { RoomnameService } from '@common/models/roomname.service';
import { RoomActions } from './store';
import { InactivityLogoutService } from '@common/models/users/inactivity-logout.service';
import { User } from '../user/user.model';
import { PLTrackingService } from '@common/services/tracking/tracking.service';
import { LoggerService } from '@common/services/logger/logger.service';
import { FeatureFlagsService } from '@common/services/feature-flags';

const isHijacked = (user: User) => {
  return (
    user &&
    user.userStatus &&
    user.userStatus.plru &&
    user.userStatus.plru !== user.userStatus.sub
  );
};
export const validUserGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const rootRoute = route.root ?? route;
  const clinicianUsername =
    rootRoute.firstChild?.paramMap.get('clinician_username');
  const isObserverRoute = state.url.includes('observe/');
  const isStudentLogin = !isObserverRoute && Boolean(clinicianUsername);
  const currentUserService = inject(CurrentUserService);
  const router = inject(Router);
  const plAppGlobal = inject(PlAppGlobalService);
  const store = inject(Store);
  const inactivityLogoutService = inject(InactivityLogoutService);
  const trackingService = inject(PLTrackingService);
  const loggerService = inject(LoggerService);
  const featureFlagsService = inject(FeatureFlagsService);

  let isStudent = currentUserService.user?.isInGroup('student');
  if (isStudentLogin) {
    if (!isStudent) {
      return router.createUrlTree([clinicianUsername]);
    }
    loggerService.identifyUser(currentUserService.user, { isHost: false });
    plAppGlobal.setStudentMode();
    featureFlagsService.identifyUser(
      currentUserService.user,
      clinicianUsername,
    );
    store.dispatch(
      UserActions.set({
        user: currentUserService.user,
        isAuthenticated: false,
      }),
    );
    return true;
  }

  return currentUserService.getAuthenticatedUser().then(
    (authUser: AuthenticatedUser) => {
      if (!authUser.isAuthenticated) {
        return false;
      }
      let user = { ...authUser } as User;
      if (isObserverRoute) {
        const isObserver = authUser?.isInGroup('Observer');
        if (isObserver) {
          user.isObserver = true;
          user.groups = ['Observer'];
          plAppGlobal.setObserverMode();
        } else {
          return router.createUrlTree(['']);
        }
      } else {
        if (
          currentUserService.user.groups.includes('Observer') ||
          currentUserService.user.groups.includes('observer')
        ) {
          currentUserService.user.groups =
            currentUserService.user.groups.filter(
              (group: string) => group.toLowerCase() !== 'observer',
            );
          user.groups = currentUserService.user.groups;
        }
        // Initialize user services
        const hijacked = isHijacked(user);
        trackingService.setUser(user, hijacked);
        loggerService.identifyUser(user, { isHost: true });
        featureFlagsService.identifyUser(user, user.username);
      }

      plAppGlobal.setUser(currentUserService.user);
      store.dispatch(
        UserActions.set({
          user,
          isAuthenticated: true,
        }),
      );
      inactivityLogoutService.start();

      currentUserService.updateUserImage();
      return true;
    },
    () => false,
  );
};

export const validRoomGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const currentUserService = inject(CurrentUserService);
  const drfRoomModel = inject(DRFRoomModel);
  const roomnameService = inject(RoomnameService);
  const ngrxStoreService: Store<AppState> = inject(Store<AppState>);
  const router = inject(Router);

  const clinicianUsername =
    route.root.firstChild.paramMap.get('clinician_username');
  const CurrentUser = currentUserService.user;

  const rooms = drfRoomModel.collectionModel;

  if (!clinicianUsername && CurrentUser) {
    rooms.filter({
      user__userprofile__uuid: CurrentUser.uuid,
    });
  } else if (clinicianUsername !== '') {
    rooms.filter({
      user__username: clinicianUsername,
    });
  } else {
    return router.createUrlTree(['invalid-room']);
  }

  let room = null;
  return rooms.fetch({}, CurrentUser.token).pipe(
    switchMap((res: any[]) => {
      if (res.length === 0) {
        return of(router.createUrlTree(['invalid-room']));
      }
      room = res[0];
      roomnameService.value = room;
      // TODO: looks like the room resolver is invoked in other places
      // so we need to make sure this is called in waiting room as well
      if (ngrxStoreService) {
        ngrxStoreService.dispatch(
          RoomActions.setData({
            data: room,
          }),
        );
      }
      return of(true);
    }),
    catchError((error: any) => {
      if (ngrxStoreService) {
        ngrxStoreService.dispatch(
          RoomActions.setError({
            error,
          }),
        );
      }
      return throwError('InvalidRoom');
    }),
  );
};

export const duplicatedProviderCheckGuard: CanActivateFn = () => {
  const currentUserService = inject(CurrentUserService);
  const firebaseService = inject(FirebaseService);
  const store: Store<AppState> = inject(Store<AppState>);
  const currentUser = currentUserService.user;
  const router = inject(Router);
  const plAppGlobal = inject(PlAppGlobalService);

  if (plAppGlobal.isStudentMode(currentUser) || plAppGlobal.isObserverMode()) {
    return Promise.resolve(true);
  }
  return new Promise((resolve, reject) => {
    store
      .select(selectIsFirebaseInitialized)
      .pipe(
        filter(initialized => initialized),
        first(),
        catchError(async () => reject()),
      )
      .subscribe(async () => {
        const sessionRef = firebaseService.getRoomRef('session');

        let participants: RTParticipant[];

        try {
          participants = await getParticipants(sessionRef);
        } catch (err) {
          console.log('Not possible to get participants', err);
          resolve(true);
        }

        const duplicates = participants.filter(
          p => p.userId === currentUser.uuid,
        );

        if (duplicates.length) {
          store.dispatch(UserActions.setId({ id: currentUser.uuid }));
          resolve(router.createUrlTree(['duplicated-provider']));
        }

        resolve(true);
      });
  });

  async function getParticipants(sessionRef) {
    const snapshot = await sessionRef.get();
    if (!snapshot.exists()) {
      return [];
    }
    const normalizedVal = snapshot.val();
    const ids = Object.keys(normalizedVal);
    return ids.map(id => snapshotToParticipant(id, normalizedVal[id]));
  }

  function snapshotToParticipant(id: string, val: any): RTParticipant {
    return {
      id,
      ...val,
    };
  }
};
